<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Determine the empirical formula for each of the compounds described below:</p>

      <v-simple-table style="width: 550px">
        <tbody>
          <tr>
            <td style="align-content: center">
              <stemble-latex :content="compoundA" />
            </td>
            <td>
              <chemical-notation-input
                v-model="inputs.empFormulaA"
                class="mt-4 mb-4"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="align-content: center">
              <stemble-latex :content="compoundB" />
            </td>
            <td>
              <chemical-notation-input
                v-model="inputs.empFormulaB"
                class="mt-4 mb-4"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="align-content: center">
              <stemble-latex :content="compoundC" />
            </td>
            <td>
              <chemical-notation-input
                v-model="inputs.empFormulaC"
                class="mt-4 mb-4"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="align-content: center">
              <stemble-latex :content="compoundD" />
            </td>
            <td>
              <chemical-notation-input
                v-model="inputs.empFormulaD"
                class="mt-4 mb-4"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="align-content: center">
              <stemble-latex :content="compoundE" />
            </td>
            <td>
              <chemical-notation-input
                v-model="inputs.empFormulaE"
                class="mt-4 mb-4"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';

export default {
  name: 'Question218',
  components: {
    StembleLatex,
    ChemicalNotationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        empFormulaA: null,
        empFormulaB: null,
        empFormulaC: null,
        empFormulaD: null,
        empFormulaE: null,
      },
    };
  },
  computed: {
    versionVariableA() {
      return this.taskState.getValueBySymbol('versionVariableA').content;
    },
    compoundA() {
      if (this.versionVariableA.value === 1) {
        return '$\\text{hexanoic acid, }\\ce{C6H12O2}$';
      } else if (this.versionVariableA.value === 2) {
        return '$\\text{acetic acid, }\\ce{C2H4O2}$';
      } else if (this.versionVariableA.value === 3) {
        return '$\\text{butanoic acid, }\\ce{C4H8O2}$';
      } else {
        return '';
      }
    },
    versionVariableB() {
      return this.taskState.getValueBySymbol('versionVariableB').content;
    },
    compoundB() {
      if (this.versionVariableB.value === 1) {
        return '$\\text{citric acid, }\\ce{C6H8O7}$';
      } else if (this.versionVariableB.value === 2) {
        return '$\\text{ibuprofen, }\\ce{C13H18O2}$';
      } else if (this.versionVariableB.value === 3) {
        return '$\\text{acetylsalicylic acid, }\\ce{C9H8O4}$';
      } else {
        return '';
      }
    },
    versionVariableC() {
      return this.taskState.getValueBySymbol('versionVariableC').content;
    },
    compoundC() {
      if (this.versionVariableC.value === 1) {
        return '$\\text{hydrazine, }\\ce{N2H4}$';
      } else if (this.versionVariableC.value === 2) {
        return '$\\text{ethylenediamine, }\\ce{C2H8N2}$';
      } else if (this.versionVariableC.value === 3) {
        return '$\\text{ethylenetetraamine, }\\ce{C2H8N4}$';
      } else {
        return '';
      }
    },
    versionVariableD() {
      return this.taskState.getValueBySymbol('versionVariableD').content;
    },
    compoundD() {
      if (this.versionVariableD.value === 1) {
        return '$\\text{nicotine, }\\ce{C10H14O2}$';
      } else if (this.versionVariableD.value === 2) {
        return '$\\text{ribose, }\\ce{C5H10O5}$';
      } else if (this.versionVariableD.value === 3) {
        return '$\\text{glucose, }\\ce{C6H12O6}$';
      } else {
        return '';
      }
    },
    versionVariableE() {
      return this.taskState.getValueBySymbol('versionVariableE').content;
    },
    compoundE() {
      if (this.versionVariableE.value === 1) {
        return '$\\text{butane, }\\ce{C4H10}$';
      } else if (this.versionVariableE.value === 2) {
        return '$\\text{hexane, }\\ce{C6H14}$';
      } else if (this.versionVariableE.value === 3) {
        return '$\\text{octane, }\\ce{C8H18}$';
      } else {
        return '';
      }
    },
  },
};
</script>
